/*!
* Online Sammlung LArchiv
* https://sammlung.larchiv.at
* Krzysztof Jeziorny <larchiv@jeziorny.net> 2023
* https://jeziorny.net
*/

@use "../../../../node_modules/@fontsource-variable/work-sans/scss/mixins" as WorkSans;
@use '../../../../node_modules/open-props-scss' as op;
@import "variables";
@import "mixins";
@import "bootstrap";
@import "grid";
@import "typography";
@import "layout";

// components
@import "components/search";
@import "components/block";
@import "components/form";
@import "components/navbar";
@import "components/footer";
@import "components/pagination";
@import "components/icon";
@import "components/image";
@import "components/theme-toggle";
@import "components/carou-scroll";
@import "components/webcare-webshare";
@import "general";
@import "../../../../node_modules/photoswipe/src/photoswipe";
@import "a11y";
@import "print";
