// copy button icon
webcare-webshare {
  display: block;
  margin-block-end: var(--gap);

  button:not(:has(svg)) {
    &::before {
      content: '';
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      margin-top: -5px;
      margin-right: 0.3rem;
      background: transparent url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"><style type="text/css"><![CDATA[ rect, path {stroke: %23222}]]></style><rect width="14" height="14" x="8" y="8" rx="2" ry="2"/><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"/></svg>') 50% 50% no-repeat;
      background-size: contain;
    }
  }
}
